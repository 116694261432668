<template>
  <div>
    <div class="header-top bg1">
      <div class="wrap header-top-box">
        <div
          @mouseover="app_show = 1"
          @mouseout="app_show = 0"
          :class="{ app_top_active: app_show == 1 }"
          class="fl"
        >
          <img src="@/assets/image/top_APP_icon@2x.png" alt />
          手机APP
          <div v-show="app_show == 1" class="header-app-img">
            <div class="fl">
              <div id="qrcode1" ref="qrcode1"></div>
              Android版
              <i></i>
              <i></i>
            </div>
            <div class="fl">
              <div id="qrcode2" ref="qrcode2"></div>
              IOS版
            </div>
          </div>
        </div>
        <!-- @mouseover="pharmacy_Addres_show = 1"  @mouseout ="pharmacy_Addres_show = 0"  -->
        <!-- 				<div @mouseover="pharmacy_Addres_show = 1" @mouseout="pharmacy_Addres_show = 0" :class="{ app_top_active: pharmacy_Addres_show == 1 }"
				 style="width:220px" class="fl" v-if="loactionList != ''">
					<img src="@/assets/image/top_position_icon@2x.png" alt />
					{{shop.shop_name}}
					<div v-show="pharmacy_Addres_show == 1" class="header-Pharmacy-addres">
						<div class="h-p-a-top">
							<div @click="pharmacy_Addres_search = 0" :class="{ pharmacy_Addres_active: pharmacy_Addres_search == 0 }">选择药店</div>
							<div @click="pharmacy_Addres_search = 1" :class="{ pharmacy_Addres_active: pharmacy_Addres_search == 1 }">搜索药店</div>
							<i></i>
							<img @click="pharmacy_Addres_show = 0" src="@/assets/image/Persondetails_Popup_Close_icon@2x.png" alt />
						</div>
					
						<div v-show="pharmacy_Addres_search == 0" class="search">
							<div @click="shopTab(v)" v-for="(v, i) in loactionList" :key="i" class="search-list">
								<div class="search-list-min">
									<div class="fl">
										<p class="one s-l-m-t">{{ v.shop_name }}</p>
										<p class="one">{{ v.address }}</p>
									</div>
									<div class="fr">详细地址</div>
								</div>
							</div>
						</div>
					
						<div v-show="pharmacy_Addres_search == 1" class="search">
							<div class="search-top">
								<input type="text" v-model="keyWord" placeholder="请输入店名" />
								<span @click="searchPharmacy">搜索</span>
							</div>
							<div class="search-list">
								<div class="search-list-min" @click="shopTab(v)" v-for="(v, i) in Pharmacy" :key="i">
									<div class="fl">
										<p class="one s-l-m-t">{{ v.shop_name }}</p>
										<p class="one">{{ v.address }}</p>
									</div>
									<div class="fr">详细地址</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->
        <div v-if="$user.state == 0" class="fr">
          请
          <span>
            <router-link to="/land?treaty_show=0">登录</router-link>
          </span>
          或
          <span>
            <router-link to="/land?treaty_show=1">免费注册</router-link>
          </span>
        </div>
        <div v-if="$user.state == 1" class="fr user">
          <div class="news" @click="GoNews()">
            消息
            <span v-if="messageNum != 0" class="circle"></span>
          </div>
          <div :class="{ userListbgw: userList == 1 }" class="i">
            <router-link to="/user/index">
              <p class="one" @mouseover="userList = 1" @mouseout="userList = 0">
                {{ user.user_nickname }}
                <img src="../../assets/image/top_Retract_icon@2x.png" />
              </p>
            </router-link>
          </div>
          <div
            v-show="userList == 1"
            class="list"
            @mouseover="userList = 1"
            @mouseout="userList = 0"
          >
            <div
              @click="goUser(v)"
              v-for="(v, i) in userArr"
              :key="i"
              @mouseover="userListActive = i"
              @mouseout="userListActive = -1"
              :class="{ userActiveColor: userListActive == i }"
            >
              <img :src="v.img" alt />
              {{ v.txt }}
            </div>
            <!-- <div><img src="../../assets/image/top_Medicalbox_icon@2x.png" />我的问诊</div>
						<div><img src="../../assets/image/top_Doctor_icon@2x.png" />我的医生</div>
						<div><img src="../../assets/image/top_drug_icon@2x.png" />我的用药</div>
						<div><img src="../../assets/image/top_archives_icon@2x.png" />我的档案</div>
            <div><img src="../../assets/image/top_Order_icon@2x.png" />我的订单</div>-->
            <div>
              <span
                @click="goUserData()"
                style="border-right: 1px solid #f5f5f5"
                >设置</span
              >
              <span @click="dialogVisible = true">退出</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title :visible.sync="dialogVisible" width="30%">
      <span>确定要退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="removeToekn()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AMap from "AMap"; // 引入高德地图
import { get_shop_list } from "@/request/public"; //门店
import { search_shop_list } from "@/request/public";
import { usergetinfo } from "@/request/user"; //个人信息
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import QRCode from "qrcodejs2";
// import { app } from "@/request/index"; //APP下载
export default {
  data() {
    return {
      dialogVisible: false,
      app_show: 0, //手机app 二维码下载 显示
      pharmacy_Addres_show: 0, //药店选择 显示隐藏
      pharmacy_Addres_search: 0, // p判断 是不是 在 搜索页面
      lat: -1, //经纬
      lng: -1, //经纬
      loactionList: [], //药店选择
      Pharmacy: [], //药店搜索列表
      userState: 0, // 是否登录
      userList: 0, // 个人列表
      userListActive: -1, // 列表选择
      keyWord: "", // 搜索关键词
      userArr: [
        {
          txt: "我的问诊",
          url: "/user/Interrogation",
          img: require("@/assets/image/top_Medicalbox_icon@2x.png"),
        },
        {
          txt: "我的医生",
          url: "/user/myDoctor",
          img: require("@/assets/image/top_Doctor_icon@2x.png"),
        },
        {
          txt: "我的用药",
          url: "/user/myDrug",
          img: require("@/assets/image/top_drug_icon@2x.png"),
        },
        {
          txt: "我的档案",
          url: "/user/archives",
          img: require("@/assets/image/top_archives_icon@2x.png"),
        },
        {
          txt: "我的订单",
          url: "/user/allOrder",
          img: require("@/assets/image/top_Order_icon@2x.png"),
        },
      ],
      user: [],
      shop: [],
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    if (datas != null) {
      this.$user.token = datas;
      this.$user.state = 1;
      this.start();
    } else {
      this.$user.state = 0;
    }
    this.getLocation();
  },
  mounted() {
    this.qrImg();
  },
  watch: {
    keyWord(keywords) {
      if (keywords == "") {
        this.Pharmacy = [];
        return;
      }
      search_shop_list({
        lng: this.lng,
        lat: this.lat,
        keywords: this.keyWord,
      }).then((res) => {
        if (res.code == 1) {
          this.Pharmacy = res.data;
        }
      });
    },
  },
  computed: {
    ...mapState(["sendMessage", "messageNum"]),
  },
  methods: {
    GoNews() {
      this.$router.replace({
        path: "/user/news",
      });
    },
    goUserData() {
      this.$router.replace({
        path: "/user/userData?state=0",
      });
    },
    goUser(v) {
      this.$router.replace({
        path: v.url,
      });
    },
    qrImg() {
      this.$axios.post("/api/public/getSysconf").then((res) => {
        if (res.data.code == 1) {
          new QRCode("qrcode1", {
            width: 302, // 二维码宽度
            height: 302, // 二维码高度
            text: res.data.data.app_android_url,
          });
          new QRCode("qrcode2", {
            width: 302, // 二维码宽度
            height: 302, // 二维码高度
            text: res.data.data.app_ios_url,
          });
        }
      });
    },
    shopTab(v) {
      this.shop = v;
      let id = v.id;
      localStorage.setItem("lzyyShopLng", v.lng);
      localStorage.setItem("lzyyShopLat", v.lat);
      localStorage.setItem("lzyyShopId", id);
      let url = this.$route.path;
      if (url.includes("/shopAddress")) {
        this.$emit("shopTab", id);
      }
      this.$router.push({
        path: "/shopAddress?id=" + id,
      });
    },
    removeToekn() {
      this.dialogVisible = false;
      localStorage.removeItem("lzyyToken");
      this.$user.state = 0;
      this.$user.token = "";
      this.$router.replace({
        path: "/",
      });
      this.closeSocket();
    },
    closeSocket() {
      let obj = {
        method: "logout",
      };
      this.sendMessage(JSON.stringify(obj));
      this.setLoginSocket(false);
    },
    Gosearch() {},
    send() {},
    start() {
      usergetinfo({
        token: this.$user.token,
      }).then((res) => {
        this.user = res.data;
        //token 返回 1001
        this.userType(res.data.cert_type);
        Vue.prototype.userinfo = res.data;
      });
    },
    userType(cert_type) {
      let Athis = this;
      if (cert_type === 0) {
        setTimeout(function () {
          // Athis.$router.push({ path: "./" });
        }, 0);
      } else if (cert_type === 1) {
        setTimeout(function () {
          Athis.$router.push({
            path: "/home/doctor/index",
          });
        }, 0);
      } else if (cert_type === 2) {
        setTimeout(function () {
          Athis.$router.push({
            path: "/home/expert/index",
          });
        }, 0);
      } else if (cert_type === 3) {
        setTimeout(function () {
          Athis.$router.push({
            path: "/home/pharmacist/index",
          });
        }, 0);
      } else if (cert_type === 4) {
        setTimeout(function () {
          Athis.$router.push({
            path: "/home/deployment/index",
          });
        }, 0);
      } else if (cert_type === 5) {
        setTimeout(function () {
          Athis.$router.push({
            path: "/home/check/index",
          });
        }, 0);
      }
    },
    searchPharmacy() {
      search_shop_list({
        lng: this.lng,
        lat: this.lat,
        keywords: this.keyWord,
      }).then((res) => {
        if (res.code == 1) {
          this.Pharmacy = res.data;
        }
      });
    },
    location() {
      get_shop_list({
        lng: this.lng,
        lat: this.lat,
      }).then((res) => {
        if (res.data.data != "") {
          this.loactionList = res.data.data;
          this.shop = this.loactionList[0];
          let id = this.loactionList[0].id;
          localStorage.setItem("lzyyShopId", id);
          localStorage.setItem("lzyyShopLng", this.lng);
          localStorage.setItem("lzyyShopLat", this.lat);
        } else {
          localStorage.removeItem("lzyyShopId");
        }
      });
    },
    getLocation() {
      if (this.$store.state.isLocation) {
        this.lng = localStorage.getItem("lzyyShopLng");
        this.lat = localStorage.getItem("lzyyShopLat");
        this.location();
        return;
      }
      let Athis = this;
      var map = new AMap.Map("container", {
        resizeEnable: true,
      });
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
            onComplete(result);
          } else {
            onError(result);
          }
        });
      });
      //解析定位结果
      function onComplete(data) {
        Athis.lat = data.position.lat;
        Athis.lng = data.position.lng;
        Athis.location();
        Athis.$store.state.isLocation = true;
      }
      //解析定位错误信息
      function onError(data) {
        Athis.lat = 35.55122265;
        Athis.lng = 116.78393841;
        Athis.location();
        Athis.$store.state.isLocation = true;
      }
    },
    ...mapMutations(["setLoginSocket"]),
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/less/header";
</style>
